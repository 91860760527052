var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "branch-container parametrizacao" }, [
        _vm.lastLog
          ? _c(
              "div",
              { staticClass: "history" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { round: "" },
                    on: {
                      click: function ($event) {
                        _vm.popupHistoricoAlteracoes = true
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-timer" }),
                    _vm._v(" Histórico de alterações"),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "main-card" },
          [
            _c(
              "div",
              { staticClass: "card-data" },
              [
                _c("br"),
                _c("el-alert", {
                  attrs: {
                    title: "Aviso",
                    type: "info",
                    closable: false,
                    description:
                      "Preencha todos os campos obrigatórios para garantir o funcionamento correto do sistema. As definições nesta área afetam diretamente o processo de renovação de apólices e a experiência do usuário, incluindo notificações",
                    "show-icon": "",
                  },
                }),
                _c(
                  "vs-col",
                  {
                    staticClass: "title",
                    attrs: { "vs-w": "12", "vs-sm": "12" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "bold",
                        staticStyle: {
                          color: "#636363",
                          "font-size": "19px",
                          "margin-left": "-10px",
                        },
                      },
                      [
                        _vm._v(
                          "\n            Perfis de Usuário para Email\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-select",
                  {
                    attrs: { multiple: "", placeholder: "Selecione os perfis" },
                    on: {
                      "remove-tag": function ($event) {
                        return _vm.remove($event)
                      },
                    },
                    model: {
                      value: _vm.dataParams,
                      callback: function ($$v) {
                        _vm.dataParams = $$v
                      },
                      expression: "dataParams",
                    },
                  },
                  _vm._l(_vm.Perfis, function (item) {
                    return _c("el-option", {
                      key: item.UniqueId,
                      attrs: { label: item.Name, value: item.UniqueId },
                    })
                  }),
                  1
                ),
                _c(
                  "vs-row",
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "title",
                        attrs: { "vs-w": "12", "vs-sm": "12" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "bold",
                            staticStyle: {
                              color: "#636363",
                              "font-size": "19px",
                              "margin-left": "-10px",
                            },
                          },
                          [
                            _vm._v(
                              "\n              Email de Aviso de Renovação Automática\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "vs-col",
                      { attrs: { "vs-w": "4", "vs-sm": "12" } },
                      [
                        _c("p", { staticClass: "label-input" }, [
                          _vm._v("\n              Título do email de aviso "),
                          _c("b", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                        ]),
                        _c("vs-input", {
                          staticClass: "w-full",
                          on: {
                            blur: function ($event) {
                              return _vm.updateTitle(0)
                            },
                          },
                          model: {
                            value: _vm.emailTemplate[0].Title,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailTemplate[0], "Title", $$v)
                            },
                            expression: "emailTemplate[0].Title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-col",
                      { attrs: { "vs-w": "12", "vs-sm": "12" } },
                      [
                        _c("br"),
                        _c("p", { staticClass: "label-input" }, [
                          _vm._v(
                            "\n              Mensagem do email de aviso: "
                          ),
                          _c("b", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "addtag",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addTagAction(0)
                              },
                            },
                          },
                          [_vm._v("\n              + Inserir Tag")]
                        ),
                        _c("jodit-editor", {
                          ref: "term-quill",
                          staticStyle: { height: "500px" },
                          attrs: {
                            id: "term-quill",
                            config: _vm.joditConfig,
                            "read-only": "true",
                          },
                          model: {
                            value: _vm.emailTemplate[0].Body,
                            callback: function ($$v) {
                              _vm.$set(_vm.emailTemplate[0], "Body", $$v)
                            },
                            expression: "emailTemplate[0].Body",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vs-row",
              {
                staticClass: "mt-5",
                attrs: { "vs-type": "flex", "vs-justify": "end" },
              },
              [
                _c(
                  "vs-col",
                  {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "end",
                      "vs-align": "center",
                      "vs-lg": "12",
                      "vs-sm": "12",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { round: "" },
                        on: {
                          click: function ($event) {
                            return _vm.voltar()
                          },
                        },
                      },
                      [_vm._v("Voltar")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-primary text-center",
                        on: {
                          click: function ($event) {
                            return _vm.update()
                          },
                        },
                      },
                      [_vm._v("\n            Salvar Alterações\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "history-change",
          attrs: {
            visible: _vm.popupHistoricoAlteracoes,
            "close-on-click-modal": "",
            "modal-append-to-body": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.popupHistoricoAlteracoes = $event
            },
          },
        },
        [
          _c("div", { staticClass: "pop-up-content" }, [
            _c("div", { staticClass: "pop-up-content-header" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/icons/ccg-icons/history-icon-ccg.svg"),
                  alt: "Ícone de excluir representante",
                  width: "24",
                  height: "24",
                },
              }),
              _c("h3", [_vm._v("Histórico de alterações")]),
            ]),
            _c("div", { staticClass: "pop-up-content-comparation" }, [
              _c("div", { staticClass: "pop-up-content-comparation-header" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-data" },
                  [_vm._v("\n            Dados\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider",
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-before" },
                  [_vm._v("\n            Antes\n          ")]
                ),
                _c("div", {
                  staticClass: "pop-up-content-comparation-header-divider",
                }),
                _c(
                  "div",
                  { staticClass: "pop-up-content-comparation-header-after" },
                  [_vm._v("\n            Depois\n          ")]
                ),
              ]),
              _c(
                "ul",
                { staticClass: "pop-up-content-comparation-items" },
                _vm._l(_vm.logs, function (alteracao, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "pop-up-content-comparation-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pop-up-content-comparation-item-data" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Data:\n                "
                                  ),
                                ]
                              ),
                              alteracao.Data
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "pop-up-content-comparation-item-info",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$utils.dateToDDMMYYYY(
                                              alteracao.Data
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Usuário:\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.NomeUsuario) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  _vm._v(
                                    "\n                  Ação:\n                "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(alteracao.Acao) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("div", {
                        staticClass: "pop-up-content-comparation-item-divider",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-before",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-info",
                                },
                                [
                                  alteracao.Antes.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showItens(
                                                alteracao.Antes
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "pop-up-content-comparation-item-divider-arrow",
                        },
                        [_c("i", { staticClass: "el-icon-right" })]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "pop-up-content-comparation-item-after",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "pop-up-content-comparation-item-row",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pop-up-content-comparation-item-title",
                                },
                                [
                                  alteracao.Depois.length > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-view",
                                            circle: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showItens(
                                                alteracao.Depois
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Visualizar")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer pop-up-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.popupHistoricoAlteracoes = false
                    },
                  },
                },
                [_vm._v("Fechar")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: "Inserir Tag na mensagem", active: _vm.modalTags },
          on: {
            "update:active": function ($event) {
              _vm.modalTags = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col items-center pt-4" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "Escolha a tag desejada",
                  },
                  model: {
                    value: _vm.tagSelected,
                    callback: function ($$v) {
                      _vm.tagSelected = $$v
                    },
                    expression: "tagSelected",
                  },
                },
                _vm._l(_vm.tags, function (item) {
                  return _c("el-option", {
                    key: item.Name,
                    attrs: { label: item.Label, value: item.Name },
                  })
                }),
                1
              ),
              _c("div", { staticClass: "vx-row flex justify-center mt-5" }, [
                _c(
                  "div",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mr-1",
                        attrs: { color: "red" },
                        nativeOn: {
                          click: function ($event) {
                            _vm.modalTags = false
                            _vm.tagSelected = ""
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("\n              Cancelar\n            "),
                        ]),
                      ]
                    ),
                    _c(
                      "vs-button",
                      {
                        attrs: { disabled: _vm.tagSelected.lenght <= 0 },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.addTagObject()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("\n              Confirmar\n            "),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }